import React from 'react';
import Breadcrumb from './Breadcrumb';
import HeaderOne from '../../components/header/HeaderOne';
import Footer from '../../components/footer/Footer';
import BlogContent from './BlogContent';

function Blog5() {
  return (
    <>
      <HeaderOne />
      <main>
        <section className="blog-area primary-bg pt-120 pb-175">
          <div className="container">
            <div className="row">
              <div className="blog-list-post blog-details-wrap">
                <div className="blog-list-post-content">
                  <div className="blog-list-post-tag mb-25">
                    <a href="/#">Weekly Progress Report 📈</a>
                  </div>
                  <h2>SnakeFeet Team & Game Design Update! 🐍👣🎮</h2>
                  <div className="blog-meta">
                    <ul>
                      <li>
                        bY <a href="/#">Byron Ma</a> April 5, 2024
                      </li> <br/>
                    </ul>
                  </div>
                  <p style= {{color: 'black'}}>
                    We're thrilled to announce some exciting developments at
                    SnakeFeet Studios regarding our team and game design
                    progress:
                  </p>
                  <ul>
                    <li style= {{color: 'black'}}>
                      New Teammates: Please join us in welcoming @Alec and
                      @gooby to our team! Alec will be lending his expertise to
                      crafting captivating characters and customizations, while
                      Gooby will be instrumental in developing programming and
                      networking features that will elevate our game experience.
                      👋🎉
                    </li> <br/>
                    <li style= {{color: 'black'}}>
                      Feedback: A huge thank you to everyone who participated in
                      our recent individual game design and art feedback
                      sessions! Your valuable input on themes, art styles, game
                      features, and more has been incredibly insightful. We've
                      been hard at work incorporating your feedback to ensure
                      that "Sixth Sense" exceeds your expectations. 🙏💡
                    </li> <br/>
                  </ul>
                  <p style= {{color: 'black'}}>
                    We're picking up the pace now and can't wait to share more
                    exciting updates with you soon! 🚀
                  </p>
                  <h2 style= {{color: 'black'}}>Programming Team Update! 💻</h2>
                  <p style= {{color: 'black'}}>
                    Our programming team has been making significant strides in
                    enhancing gameplay mechanics and overall performance:
                  </p>
                  <ul style= {{color: 'black'}}>
                    <li>
                      Pawprints: Implemented pawprints that follow your target,
                      reducing aimless wandering and adding strategic depth to
                      tracking. 🐾
                    </li> <br/>
                    <li>
                      Fog of War: Introduced a fog of war vision system where
                      walls block sightlines, adding tactical considerations to
                      navigation. 🌫️
                    </li> <br/>
                    <li>
                      WASD Movement: Added WASD movement based on community
                      feedback for improved control and fluidity. 🎮
                    </li> <br/>
                    <li>
                      Alliance and Betrayal System: Initiating work on the
                      alliance and betrayal system, adding layers of social
                      interaction and strategy. 🤝🗡️
                    </li> <br/>
                    <li>
                      Map Indicator: Added a map indicator to help players
                      navigate and stay oriented in the game world. 🗺️
                    </li> <br/>
                    <li>
                      Server Music: Optimized server performance by disabling
                      music playback, ensuring smoother gameplay experiences. 🎵
                    </li> <br/>
                    <li>
                      Kill Cooldown: Reintroduced a kill cooldown mechanic for
                      balanced and fair gameplay dynamics. ⏰
                    </li> <br/>
                  </ul>
                  <h2 style= {{color: 'black'}}>Art Team Update! 🎨</h2>
                  <p style= {{color: 'black'}}>
                    Our art team has been busy refining the visual aspects of
                    "Sixth Sense" to create an immersive and engaging
                    experience:
                  </p>
                  <ul style= {{color: 'black'}}>
                    <li>
                      Theme Revision: After carefully reviewing feedback, we're
                      revisiting the game's theme to align it more closely with
                      its mechanics and emotional impact. 🌿
                    </li> <br/>
                    <li>
                      Art Style Adjustments: Making adjustments to the overall
                      art style to combine a warm, inviting atmosphere with
                      intricate details that breathe life into characters and
                      environments. 🌄
                    </li> <br/>
                    <li>
                      UI Formatting: Working on enhancing UI formatting and
                      layouts across all screens for a seamless and intuitive
                      user experience. 📱
                    </li> <br/>
                  </ul>
                  <p style= {{color: 'black'}}>
                    Stay tuned for more updates as we continue to work
                    tirelessly to bring "Sixth Sense" to life! 🚀
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Blog5;