import React from 'react';
import Breadcrumb from './Breadcrumb';
import HeaderOne from '../../components/header/HeaderOne';
import Footer from '../../components/footer/Footer';
import BlogContent from './BlogContent';

function Blog2() {
  return (
    <>
      <HeaderOne />
      <main>
        <section className="blog-area primary-bg pt-120 pb-175">
          <div className="container">
            <div className="row">
              <div className="blog-list-post blog-details-wrap">
                <div className="blog-list-post-content">
                  <div className="blog-list-post-tag mb-25">
                    <a href="/#">GDC Update! 🎮</a>
                  </div>
                  <h2>GDC Trip and Development Progress! 🚀</h2>
                  <div className="blog-meta">
                    <ul>
                      <li>
                        bY <a href="/#">Byron Ma</a> March 15, 2024
                      </li> <br/>
                    </ul>
                  </div>
                  <p style= {{color: 'black'}}>Hello, SnakeFeet Studios community! 👋</p>
                  <p style= {{color: 'black'}}>
                    We are excited to share the latest updates from our studio,
                    encompassing both our participation in the upcoming Game
                    Developers Conference (GDC) and the strides we've made in
                    game development. 🎉
                  </p>
                  <p style= {{color: 'black'}}>GDC: Meetings and Game Demos 🤝</p>
                  <p style= {{color: 'black'}}>
                    We are delighted to announce that Byron and Ethan will
                    represent SnakeFeet Studios at the Game Developers
                    Conference (GDC) from March 17-22. This is a significant
                    opportunity for our team to engage with other industry
                    professionals, explore potential collaborations, and present{' '}
                    <em>Sixth Sense</em> at various showcases. 🌟
                  </p>
                  <p style= {{color: 'black'}}>
                    We invite anyone attending GDC who is interested in
                    discussing meeting up, asking questions about our game, or
                    playing <em>Sixth Sense</em> firsthand to connect with us
                    through the GDC portal. It is an excellent opportunity for
                    us to share our vision and progress with a broader audience.
                    🤗
                  </p>
                  <p style= {{color: 'black'}}>Development Updates 🛠️</p>
                  <p style= {{color: 'black'}}>
                    Our teams have been diligently working on development, and
                    we are pleased to report substantial progress across the
                    board:
                  </p>
                  <ul style= {{color: 'black'}}>
                    <li>
                      <strong>Art Team</strong>: Our Art Team has introduced new
                      designs for essential game elements, including the wheel
                      and Sixth Sense mechanics, and gameplay buttons. We are
                      also debuting spectator emotes and laying the foundation
                      for in-game emotes. Additionally, we have completed a
                      modular customization system, facilitating the easy
                      addition of new species, skins, outfits, and weapons,
                      thereby allowing for rapid scaling and customization. 🎨
                    </li> <br/>
                    <li>
                      <strong>Programming Team</strong>: The Programming Team
                      continues to make significant updates. We have
                      successfully implemented a Challenge system, refined
                      animations, and introduced In-Game and Spectator Emotes.
                      Our current focus is on integrating bots to populate game
                      lobbies and developing immersive sound effects to enhance
                      the gameplay experience. 💻
                    </li> <br/>
                  </ul>
                  <p style= {{color: 'black'}}> Sincerely,</p>
                  <p style= {{color: 'black'}}>The SnakeFeet Studios Team 🐍👣</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Blog2;