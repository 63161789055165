import React from 'react';
import Breadcrumb from './Breadcrumb';
import HeaderOne from '../../components/header/HeaderOne';
import Footer from '../../components/footer/Footer';
import GameSingleArea from './GameSingleArea';
import ShopArea from '../../pages/store/ShopArea.jsx';
import Video from '../../components/index/indexone/Video';

function Overview() {
  return (
    <>
      <HeaderOne />
      <main>
        {/* <Breadcrumb/> */}
        <Video />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '40px' }}>
          <div style={{ flex: 1, marginRight: '40px', marginLeft: '40px', marginTop: '150px',}}>
            <h3>
			Eliminating targets is how the circle of players shrink in the game. Once you eliminate your target, you get a new one. The game is currently limited to short-range weapons only. </h3> <h3>
<br></br>
Invisibility is the primary evasion mechanic in the game. It is well-balanced, with limitations such as moving in slow motion, being unable to kill while invisible, and remaining vulnerable to being killed. You can use invisibility at any time to run away from a potential assassin or sneak up on your target. However, beware of the Sixth Sense feature, which notifies your assassin when you are invisible. </h3> <h3>
<br></br>

The Sixth Sense is a hot or cold meter that tells you how far you are from your target. When your target goes invisible, use your Sixth Sense to hunt them down. The dial at the top left of the video moves as you get closer to your target.
            </h3>
          </div>
          <div style={{ flex: '0 0 300px' }}>
            <img 
              src="assets/img/images/phonemockup4.png"
              alt="phone screenshot"
              style={{ width: 'auto', height: '400px' }}
            />        
          </div>
        </div>
		<div style={{ display: 'flex', alignItems: 'center', marginTop: '40px' }}>
		<div style={{ flex: '0 0 300px' }}>
            <img 
              src="assets/img/images/phonemockup3.png"
              alt="phone screenshot"
              style={{ width: 'auto', height: '400px' }}
            />        
          </div>
          <div style={{ flex: 1, marginRight: '40px', marginLeft: '40px', marginBottom: '50px' }}>
            <h3>
			The Wheel is the main deduction feature of the game. It has 50 slots for 50 players, arranged in the targeting order following the arrows. Information is initially hidden, but you can uncover it by walking around the map and collecting information from dead bodies lying on the floor. Use your Wheel to solve the targeting order and identify your potential assassins.</h3> <h3> <br></br>
			Challenges take place in the lobby and include an optional wager system. Players can wager their Honey (the in-game currency) against other players, creating a "Honey Pot." The winner takes all. Challenges serve multiple goals: raising the stakes in-game and making the currency more central to the game design, encouraging players to engage with it rather than simply counting it.</h3> <h3>
<br></br>

Emotes are one area of customization that players can unlock with in-game currency. They can be used both in-game and in the lobby, ranging from basic actions to viral dances.            </h3>
          </div>
        </div>
		<GameSingleArea />

        {/* <ShopArea /> */}
		
      </main>
      <Footer />
    </>
  );
}

export default Overview;