import React from 'react';
import HeaderOne from '../../../components/header/HeaderOne';
import Footer from '../../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import AboutInner from './AboutInner'
import LatestMatch from './LatestMatch'
import TeamMem from './TeamMem'
import GamesArea from '../../../components/index/indexone/GamesArea';
import GameSingleArea from '../../overview/GameSingleArea';

function AboutUs() {
  return (
	<>
	<HeaderOne/>
	<main>

	{/* <Breadcrumb/> */}

	{/* <AboutInner/> */}

	<LatestMatch/> 

	<TeamMem/>

	<GamesArea/>

	
	</main>
	<Footer/>
	</>
  )
}

export default AboutUs
