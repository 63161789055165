import { BrowserRouter as Router, Switch} from 'react-router-dom';
// import HomeTwo from './pages/homes/HomeTwo';
import HomeOne from './pages/homes/HomeOne';
// import HomeThree from './pages/homes/HomeThree';
import Shop from './pages/store/Shop';
import Contact from './pages/contact/Contact';
import Community from './pages/community/Community';
import Overview from './pages/overview/Overview';
import AboutUs from './pages/aboutpage/aboutuspage/AboutUs';
import Upcoming from './pages/aboutpage/upcoming/Upcoming';
import GameSingle from './pages/aboutpage/singlegame/GameSingle';
import News from './pages/blogs/Blogs';
import Blog1 from './pages/blogdetails/Blog1'
import Blog2 from './pages/blogdetails/Blog2'
import Blog3 from './pages/blogdetails/Blog3'
import Blog4 from './pages/blogdetails/Blog4'
import Blog5 from './pages/blogdetails/Blog5'
import Blog6 from './pages/blogdetails/Blog6'
import Blog7 from './pages/blogdetails/Blog7'
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import HomeFour from './pages/homes/HomeFour';
import HomeFive from './pages/homes/HomeFive';
import HomeSix from './pages/homes/HomeSix';
import HomeSeven from './pages/homes/HomeSeven';
// import Loader from "react-js-loader";





function App() {
      //  const [loading,setLoading] = useState(false);

      //  useEffect(()=>{
      //   setLoading(true)
      //   setTimeout(()=>{
      //     setLoading(false)
      //   },8000)
      //  },[])
      //  {
      //   loading ?(
      //     <MoonLoader color={color} loading={loading} css={override} size={150} />
      //   )
         
  
      // }
  return (
    
    <div className="App">
      
     <Router>
       
        <Switch>
        {/* <ScrollToTopRoute exact={true} path='/index-1'>
          <HomeTwo/>
        </ScrollToTopRoute> */}
        <ScrollToTopRoute exact={true} path='/'>
        <HomeOne/>
        </ScrollToTopRoute>
        {/* <ScrollToTopRoute exact={true} path='/index-3'>
        <HomeThree/>
        </ScrollToTopRoute> */}
        <ScrollToTopRoute exact={true} path='/shop'>
         <Shop/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/contact'>
        <Contact/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/community'>
        <Community/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/sixthsense'>
        <Overview/>
        </ScrollToTopRoute>
        
        <ScrollToTopRoute exact={true} path='/about'>
        <AboutUs/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/upcoming-games'>
        <Upcoming/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/game-single'>
        <GameSingle/>
        </ScrollToTopRoute>
        
        <ScrollToTopRoute exact={true} path='/news'>
        <News/>
        </ScrollToTopRoute>
        
        <ScrollToTopRoute exact={true} path='/02-09'>
        <Blog1/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/03-09'>
        <Blog2/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/03-22'>
        <Blog3/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/03-29'>
        <Blog4/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/04-05'>
        <Blog5/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/04-12'>
        <Blog6/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/04-19'>
        <Blog7/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/index-4'>
        <HomeFour/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/index-5'>
        <HomeFive/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/index-6'>
        <HomeSix/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/index-7'>
        <HomeSeven/>
        </ScrollToTopRoute>
        
     </Switch>
     </Router>
     
    </div>
  );
}

export default App;
