import React,{useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import ScrollToTop from "react-scroll-to-top";


function HeaderOne() {
  
  useEffect(()=>{
    //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .push-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });


        $('.menu-backdrop, .mobile-menu .close-btn').click (()=>{
          $('body').removeClass('mobile-menu-visible');
        })


        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });
      }

  },[]);


const customLinkStyle = {
  fontSize: '25px', // Larger text size
  fontFamily: '"Arial", sans-serif', // Example of a different font
};

const customLogoStyle = {
  width: '150px',
};
  

  return (
	  <header>
       <ScrollToTop smooth top="500" color="#000" />

       <div id="header" className="main-header menu-area menu" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <div className="container-fluid container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
              <div className="main-menu">
                <nav>
                  <div className="navbar-wrap push-menu d-none d-lg-flex">
                  <div className="logo">
                    <Link to="/"><img src="assets/img/logo/SFSLogoGreen.png" alt="Logo" style={{ height: '80px', marginTop: '20px' }} /></Link>
                  </div>
                    <ul className="navigation">
                      <li><NavLink to="/" style={{ fontSize: '18px', fontFamily: '"Arial", sans-serif' }}>Home</NavLink></li>
                      <li><NavLink to="/sixthsense" style={{ fontSize: '18px', fontFamily: '"Arial", sans-serif' }}>Game</NavLink></li>
                      <li><NavLink to="/news" style={{ fontSize: '18px', fontFamily: '"Arial", sans-serif' }}>News</NavLink></li>
                      <li><NavLink to="/about" style={{ fontSize: '18px', fontFamily: '"Arial", sans-serif' }}>About</NavLink></li>
                      <li><NavLink to="/contact" style={{ fontSize: '18px', fontFamily: '"Arial", sans-serif' }}>Contact</NavLink></li>
                    </ul>
                  </div>
                   
                    <div className="header-action">
                      <ul>
                      </ul>
                    </div>
                  </nav>
                </div>
               <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn"><i className="fas fa-times" /></div>
                    <div className="nav-logo">                      <Link to="/"><img src="assets/img/logo/SFSLogoGreen.png" alt="Logo" /></Link>
                    </div>
                    <div className="menu-outer">
                  
                    </div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="/#"><span className="fab fa-twitter" /></a></li>
                        <li><a href="/#"><span className="fab fa-facebook-square" /></a></li>
                        <li><a href="/#"><span className="fab fa-pinterest-p" /></a></li>
                        <li><a href="/#"><span className="fab fa-instagram" /></a></li>
                        <li><a href="/#"><span className="fab fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
              </div>
              {/* Modal Search */}
              <div className="modal fade" id="search-modal" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form>
                      <input type="text" placeholder="Search here..." />
                      <button><i className="fa fa-search" /></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  )
}
export default HeaderOne;
