import React from 'react';

// Utility function to scroll the window down one screen length
const scrollToNextScreen = () => {
  window.scrollBy({
    top: window.innerHeight,
    behavior: 'smooth',
  });
};

function OneSlider() {
  // Inline style to reset margin and padding for the component and its children
  const resetStyle = {
    margin: 0,
    marginTop: 0,
    padding: 0,
    boxSizing: 'border-box', // Ensures padding and border are included in the total width and height
  };

  // Apply resetStyle to each element where needed to ensure no unwanted spacing
  return (
    <section className="slider-area slider-bg" style={{ ...resetStyle, position: 'relative', width: '100vw', height: '80vh', marginTop: '50px', marginBottom: '-35px' }}>
      <div className="container" style={{ ...resetStyle, width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ 
  ...resetStyle, 
  position: 'absolute', 
  top: '70px', 
  left: '50%', // Center horizontally
  transform: 'translateX(-50%)', // Adjust for the element's width
  width: 'auto', // Adjust the width as needed
  textAlign: 'center', 
  color: 'white', 
  zIndex: '10', 
  // backgroundColor: 'red'
}}>
  {/* <div>
  <p onClick={scrollToNextScreen} style={{ fontSize: '30px', cursor: 'pointer'}}>Learn more!</p>
  <div onClick={scrollToNextScreen} style={{  
    ...resetStyle, 
    cursor: 'pointer', 
    fontSize: '24px', 
    marginTop: '-20px', 
  }}>
    ﹀
  </div>
  </div> */}
</div>

        <div className="row justify-content-center" style={{ ...resetStyle, width: '100%', height: '100%' }}>
          <div className="col-xl-12" style={{ ...resetStyle, width: '100%', height: '100%' }}>
            {/* Image Section */}
            {/* <img src="assets/img/images/HomePageHero.png" alt="Snakefeet Studios" style={{ width: '100vw', height: '100vh', objectFit: 'cover', position: 'absolute', left: 0, top: 0 }} /> */}

            <div className="slider-content-wrapper" style={{ ...resetStyle, width: '100%', height: '100%', position: 'absolute' }}>
              <img src="assets/img/images/cold-new-img.png" alt="Snakefeet Studios" style={{ width: '100vw', height: '110vh', objectFit: 'cover', position: 'absolute', left: 0, top: -200 }} />
            </div>
            {/* "Coming soon!" Text */}

          </div>
        </div>
      </div>
    </section>
  );
}

export default OneSlider;
