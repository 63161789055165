import React from 'react';

function TeamMem() {
  // Inline style for team members to ensure 5 items per row
  const teamMemberStyle = {
    width: '16%', // Adjusted width to account for margins
    margin: '0 2%', // 2% margin on the left and right
    marginBottom: '50px', // Keep the original marginBottom for consistency
  };

  const sectionStyle = {
    backgroundColor: '#131313', // Dark background color for the section
  };

  return (
    <section className="team-member-area pt-115 pb-125" style={sectionStyle}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three text-center mb-70">
              <h2 style={{color: 'white'}}>Meet the team</h2>
            </div>
          </div>
        </div>
        <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}> {/* Ensure wrapping */}
          {/* Generate team members */}
          {[...Array(11)].map((_, index) => (
            <div key={index} style={teamMemberStyle} className="team-member-box text-center">
              <div className="team-member-thumb">
                <img src={`assets/img/team/${index + 1}.png`} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TeamMem;
