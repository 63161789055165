import React, { useState, useEffect } from 'react';

function Footer() {
    const imageData = [
      {
        id: 1,
        src: '/assets/img/images/sm/logodisc1.png',
        link: 'https://discord.gg/4UhqjXHnq6',
      },
      {
        id: 2,
        src: '/assets/img/images/sm/logoreddit1.png',
        link: 'https://www.reddit.com/r/sixthsensegame/',
      },
      {
        id: 3,
        src: '/assets/img/images/sm/logox1.png',
        link: 'https://twitter.com/playsixthsense',
      },
      {
        id: 4,
        src: '/assets/img/images/sm/logoins1.png',
        link: 'https://www.instagram.com/sixthsensegame/',
      },
      {
        id: 5,
        src: '/assets/img/images/sm/logotiktok1.png',
        link: 'https://www.tiktok.com/@sixthsensegame',
      },
      {
        id: 6,
        src: '/assets/img/images/sm/logoyoutube1.png',
        link: 'https://www.youtube.com/@SixthSenseGame',
      },
      {
        id: 7,
        src: '/assets/img/images/sm/logotwitch1.png',
        link: 'https://www.twitch.tv/sixthsensegame',
      },
    ];

  const [responsiveStyles, setResponsiveStyles] = useState({
    fontSize: '30px',
    padding: '0 20px',
    maxWidth: '60%',
  });

  useEffect(() => {
    const updateStylesBasedOnWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setResponsiveStyles({
          fontSize: '17px',
          padding: '0 10px',
          maxWidth: '90%',
        });
      } else {
        setResponsiveStyles({
          fontSize: '30px',
          padding: '0 20px',
          maxWidth: '60%',
        });
      }
    };

    updateStylesBasedOnWidth();
    window.addEventListener('resize', updateStylesBasedOnWidth);

    return () => window.removeEventListener('resize', updateStylesBasedOnWidth);
  }, []);

  return (
	   <footer>
        <div className="footer-top footer-bg" style={{height: '200px'}}>
          {/* newsletter-area */}
          <div className="newsletter-area">
            <div className="container">
              <div className="row">
              </div>
            </div>
          </div>
          {/* newsletter-area-end */}
          <div className="container" style={{paddingTop: '30px'}}>
            <div className="row justify-content-between">
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5 style={{fontSize: '40px'}}>Game</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="/sixthsense" style={{fontSize: '20px'}}>Sixth Sense</a></li>
                      {/* <li><a  href="/#" style={{fontSize: '20px'}}>Press Kit</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5 style={{fontSize: '40px'}}>News</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="/news" style={{fontSize: '20px'}}>News</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5 style={{fontSize: '40px'}}>About</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="/about" style={{fontSize: '20px'}}>About Us</a></li>
                      {/* <li><a  href="/#" style={{fontSize: '20px'}}>Terms and Conditions</a></li>
                      <li><a  href="/#" style={{fontSize: '20px'}}>Privacy Policy</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5 style={{fontSize: '40px'}}>Contact</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      {/* <li><a  href="/#" style={{fontSize: '20px'}}>FAQs</a></li> */}
                      <li><a  href="/contact" style={{fontSize: '20px'}}>Ask a Question</a></li>
                      {/* <li><a  href="/#" style={{fontSize: '20px'}}>File a Complaint</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5 style={{fontSize: '40px'}}>Follow us</h5>
                  </div>
                  <div className="footer-social">
                <div className="container" style={{ maxWidth: '100%' }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
  {imageData.map((image) => (
    <div key={image.id}>
      <a href={image.link} target="_blank" rel="noopener noreferrer">
        <img src={image.src} alt={`pic`} className="img-fluid" style={{width: '30px', height: '30px', margin: '3px' }} />
      </a>
    </div>
  ))}
</div>
                </div>
              </div>
                </div>
                {/* <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Stay up to date with <br></br>the latest news!</h5>
                  </div>
                  <div className="footer-newsletter">
                    <form >
                      <input style={{backgroundColor: 'black'}} type="text" placeholder="Enter your email" />
                      <button><i className="fas fa-rocket" /></button>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="footer-fire"><img src="assets/img/images/footer_fire.png" alt="" /></div> */}
          {/* <div className="footer-fire footer-fire-right"><img src="assets/img/images/footer_fire.png" alt="" /></div> */}
        </div>
        <div className="copyright-wrap" style={{backgroundColor: 'black'}}>
          <div className="container">
            <div className="row">
                  <img src="assets/img/logo/SFSLogoGreen.png" alt="img" height={'50px'} />
                <div className="copyright-text" style={{marginLeft: '30px'}}>
                  <p>Copyright © 2024 <a  href="/#">Snakefeet LLC</a> All Rights Reserved.</p>
                </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer;
