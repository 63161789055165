import React from 'react';
import Breadcrumb from './Breadcrumb';
import HeaderOne from '../../components/header/HeaderOne';
import Footer from '../../components/footer/Footer';
import BlogContent from './BlogContent';

function Blog7() {
  return (
    <>
      <HeaderOne />
      <main>
        <section className="blog-area primary-bg pt-120 pb-175">
          <div className="container">
            <div className="row">
              <div className="blog-list-post blog-details-wrap">
                <div className="blog-list-post-content">
                  <div className="blog-list-post-tag mb-25">
                    <a href="/#">Weekly Progress Report 📈</a>
                  </div>
                  <h2>Weekly Programming Team Update!💻</h2>
                  <div className="blog-meta">
                    <ul>
                      <li>
                        bY <a href="/#">Byron Ma</a> April 19, 2024
                      </li>
                    </ul>
                  </div>
                  <p style={{ color: 'black' }}>
                    Our programming team has been hard at work implementing
                    exciting new features and enhancements to elevate your
                    gaming experience:
                  </p>
                  <ul style={{ color: 'black' }}>
                    <li>
                      Switched Perspective to 3D: We've embarked on a journey
                      into a 3D perspective while retaining our unique
                      hand-drawn 2D sprites, enhancing immersion and visual
                      depth. 🗺
                    </li>

                    <br />

                    <li>
                      Added In-Game Chat: Introducing an in-game chat feature,
                      allowing players to communicate with others in their
                      vicinity or send direct messages to allies, fostering
                      teamwork and coordination. 💬
                    </li>
                    <br />

                    <li>
                      Portal System: Created a sophisticated portal network that
                      enables fast and efficient transportation across the map,
                      opening up new strategic possibilities. 🌀
                    </li>
                    <li>
                      Spies System: Intriguing spies now roam the game,
                      providing valuable information if your target passes by,
                      adding layers of strategy and intrigue. 🕵️
                    </li>
                    <br />

                    <li>
                      Mana System: We've introduced a dynamic mana system that
                      incentivizes aggressive playstyles. Earn mana through
                      active gameplay to hire spies and unleash powerful
                      abilities. ✨
                    </li>
                    <br />

                  </ul>
                  <p style={{ color: 'black' }}>
                    These additions and improvements are designed to enhance
                    gameplay depth, foster strategic thinking, and create a more
                    immersive and engaging gaming environment. We're excited
                    about these developments and can't wait for you to
                    experience them firsthand! Stay tuned for more updates as we
                    continue to refine and expand "Sixth Sense." 🚀
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Blog7;