import React from 'react';
import Breadcrumb from './Breadcrumb';
import HeaderOne from '../../components/header/HeaderOne';
import Footer from '../../components/footer/Footer';
import BlogContent from './BlogContent';

function Blog4() {
  return (
    <>
      <HeaderOne />
      <main>
        <section className="blog-area primary-bg pt-120 pb-175">
          <div className="container">
            <div className="row">
              <div className="blog-list-post blog-details-wrap">
                <div className="blog-list-post-content">
                  <div className="blog-list-post-tag mb-25">
                    <a href="/#">Weekly Update! 📅</a>
                  </div>
                  <h2>Weekly Update: New Game Features and Progress 🆕🎮🚀</h2>
                  <div className="blog-meta">
                    <ul>
                      <li>
                        bY <a href="/#">Byron Ma</a> March 29, 2024
                      </li> <br/>
                    </ul>
                  </div>
                  <p style= {{color: 'black'}}>
                    Starting this week, SnakeFeet Studios is excited to announce
                    that we'll be sending out weekly updates every Friday
                    evening, highlighting the progress made across various
                    aspects of our game development process. From game design
                    and programming to art, music, and beyond, these updates
                    will keep you informed about our journey towards creating an
                    unforgettable gaming experience. Stay tuned! 🎉📅
                  </p>
                  <h2 style= {{color: 'black'}}>New Game Features 🆕🎮🚀</h2>
                  <p style= {{color: 'black'}}>
                    We're thrilled to introduce some exciting new gameplay
                    systems and features that will take "Sixth Sense" to the
                    next level:
                  </p>
                  <ul style= {{color: 'black'}}>
                    <li>
                      Paw Prints 🐾: Can't find your target? Worried about
                      leaving a trace? Now, targets and Assassins leave behind a
                      trail of paw prints. Utilize the new "stealth mode" to
                      avoid leaving footprints behind or follow them to track
                      your prey.
                    </li> <br/>
                    <li>
                      Alliances/Betrayals ⚔️: Forge temporary alliances with
                      other players for mutual benefit or choose to betray them
                      to their Assassin. Each decision comes with its own risks
                      and rewards, adding depth to your strategy.
                    </li> <br/>
                    <li>
                      Power Ups 🦸‍♂️: Enhance your senses with power-ups that
                      boost your sight, smell, hearing, taste, and touch. Use
                      them strategically to gain an advantage over your
                      opponents.
                    </li> <br/>
                  </ul>
                  <p style= {{color: 'black'}}>Additional Features</p>
                  <ul style= {{color: 'black'}}>
                    <li>
                      Hiding: Need to disappear in a pinch? Look for hiding
                      spots like barrels or closets. But beware, your Assassin
                      might be waiting outside! 🕵️‍♀️
                    </li> <br/>
                    <li>
                      Bounties: Seek revenge from beyond the grave by placing a
                      honey bounty on your Assassin's head. Reward the player
                      who eliminates them and claim your vengeance. 💰
                    </li> <br/>
                  </ul>
                  <h2 style= {{color: 'black'}}>Art Team Update 🎨</h2>
                  <p style= {{color: 'black'}}>
                    Based on the feedback received at GDC, our art team is
                    making some exciting changes:
                  </p>
                  <ul style= {{color: 'black'}}>
                    <li>
                      Map: Adding environmental dangers to match our game's
                      theme. ⚠️
                    </li> <br/>
                    <li>
                      UI: Cohesion pass on all buttons for a streamlined
                      experience. 🌟
                    </li> <br/>
                    <li>
                      Characters: Adding more spectator emotes and finalizing
                      new bear skins for added customization. 🐻🎨
</li> <br/>
</ul>
</div>
</div>
</div>
</div>
</section>
</main>
<Footer />
</>
);
}
export default Blog4;