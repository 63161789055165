import React from 'react';
import { Link } from "react-router-dom";

function Blogarea() {
  // Inline style for the overall background
  const sectionStyle = {
    backgroundColor: '#393939', // Light gray background
    paddingTop: '80px',
    paddingBottom: '80px'
  };
  
  // Inline styles for the blog posts and text
  const blogPostStyle = {
    backgroundColor: '#2a2a2a', // Dark gray background
    color: 'white', // White text
    padding: '30px',
    marginBottom: '30px'
  };
  
  const titleStyle = {
    color: 'white',
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '50px',
    textAlign: 'center'
  };
  
  const thumbnailStyle = {
    fontSize: '50px',
    marginBottom: '20px',
    textAlign: 'center'
  };
  
  const blogTitleStyle = {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px'
  };
  
  const blogMetaStyle = {
    fontSize: '14px',
    color: '#ccc',
    marginBottom: '20px'
  };
  
  const blogDescStyle = {
    color: 'white',
    fontSize: '18px',
    lineHeight: '1.6',
    marginBottom: '20px'
  };
  
  const readMoreStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#ffffff',
    textDecoration: 'none',
    borderRadius: '4px',
    transition: 'background-color 0.3s'
  };

  return (
    <section className="blog-area" style={sectionStyle}>
      <div className="container">
        <h1 style={titleStyle}>SnakeFeet Studios News</h1>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {/* Blog list post 1 */}
            <div className="blog-list-post" style={blogPostStyle}>
              <div className="blog-thumbnail" style={thumbnailStyle}>
                📈
              </div>
              <div className="blog-list-post-content">
                <h2 style={blogTitleStyle}><Link to="/04-19">Weekly Progress Report - April 19, 2024</Link></h2>
                <div className="blog-meta" style={blogMetaStyle}>
                  <span>By Byron | April 19, 2024</span>
                </div>
                <p style={blogDescStyle}>Discover the exciting new features and enhancements our programming team has been implementing to elevate your gaming experience in this week's progress report.</p>
                <Link to="/04-19" style={readMoreStyle}>Read More</Link>
              </div>
            </div>
            <div className="blog-list-post" style={blogPostStyle}>
              <div className="blog-thumbnail" style={thumbnailStyle}>
                🌿
              </div>
              <div className="blog-list-post-content">
                <h2 style={blogTitleStyle}><Link to="/04-12">Weekly Progress Report - April 12, 2024</Link></h2>
                <div className="blog-meta" style={blogMetaStyle}>
                  <span>By Byron | April 12, 2024</span>
                </div>
                <p style={blogDescStyle}>Explore our creative process as we introduce the new 'Lost City in the Forest' theme and dive into the latest game design updates.</p>
                <Link to="/04-12" style={readMoreStyle}>Read More</Link>
              </div>
            </div>
            <div className="blog-list-post" style={blogPostStyle}>
              <div className="blog-thumbnail" style={thumbnailStyle}>
                🐍
              </div>
              <div className="blog-list-post-content">
                <h2 style={blogTitleStyle}><Link to="/04-05">Weekly Progress Report - April 5, 2024</Link></h2>
                <div className="blog-meta" style={blogMetaStyle}>
                  <span>By Byron | April 5, 2024</span>
                </div>
                <p style={blogDescStyle}>Join us in welcoming new teammates and learn about the exciting developments in team growth and game design at SnakeFeet Studios.</p>
                <Link to="/04-05" style={readMoreStyle}>Read More</Link>
              </div>
            </div>
            <div className="blog-list-post" style={blogPostStyle}>
              <div className="blog-thumbnail" style={thumbnailStyle}>
                🆕
              </div>
              <div className="blog-list-post-content">
                <h2 style={blogTitleStyle}><Link to="/03-29">Weekly Update: New Game Features and Progress</Link></h2>
                <div className="blog-meta" style={blogMetaStyle}>
                  <span>By Byron | March 29, 2024</span>
                </div>
                <p style={blogDescStyle}>Discover the thrilling new gameplay systems and features we're introducing to take "Sixth Sense" to the next level in our weekly update.</p>
                <Link to="/03-29" style={readMoreStyle}>Read More</Link>
              </div>
            </div>
            <div className="blog-list-post" style={blogPostStyle}>
              <div className="blog-thumbnail" style={thumbnailStyle}>
                🎮
              </div>
              <div className="blog-list-post-content">
                <h2 style={blogTitleStyle}><Link to="/03-22">GDC Recap - March 22, 2024</Link></h2>
                <div className="blog-meta" style={blogMetaStyle}>
                  <span>By Byron | March 22, 2024</span>
                </div>
                <p style={blogDescStyle}>Relive our incredible experience showcasing "Sixth Sense" at the Game Developers Conference and learn about our plans for the future.</p>
                <Link to="/03-22" style={readMoreStyle}>Read More</Link>
              </div>
            </div>
            <div className="blog-list-post" style={blogPostStyle}>
              <div className="blog-thumbnail" style={thumbnailStyle}>
                🚀
              </div>
              <div className="blog-list-post-content">
                <h2 style={blogTitleStyle}><Link to="/03-09">GDC Trip and Development Progress - March 9, 2024</Link></h2>
                <div className="blog-meta" style={blogMetaStyle}>
                  <span>By Byron | March 9, 2024</span>
                </div>
                <p style={blogDescStyle}>Join us on our journey to GDC and discover the latest updates on our game development progress in this exciting blog post.</p>
                <Link to="/03-09" style={readMoreStyle}>Read More</Link>
              </div>
            </div>
            <div className="blog-list-post" style={blogPostStyle}>
              <div className="blog-thumbnail" style={thumbnailStyle}>
                🎉
              </div>
              <div className="blog-list-post-content">
                <h2 style={blogTitleStyle}><Link to="/02-09">Exciting Progress Update from the SnakeFeet Team</Link></h2>
                <div className="blog-meta" style={blogMetaStyle}>
                  <span>By Byron | February 9, 2024</span>
                </div>
                <p style={blogDescStyle}>Celebrate with us as we share the incredible progress our teams have made on "Sixth Sense" and get a sneak peek at what's coming next.</p>
                <Link to="/02-09" style={readMoreStyle}>Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blogarea;