import React from 'react';
import Breadcrumb from './Breadcrumb';
import HeaderOne from '../../components/header/HeaderOne';
import Footer from '../../components/footer/Footer';
import BlogContent from './BlogContent';

function Blog1() {
  return (
    <>
      <HeaderOne />
      <main>
        <section className="blog-area primary-bg pt-120 pb-175">
          <div className="container">
            <div className="row">
              <div className="blog-list-post blog-details-wrap">
                <div className="blog-list-post-content">
                  <div className="blog-list-post-tag mb-25">
                    <a href="/#">Updates! 🎉</a>
                  </div>
                  <h2>Exciting Progress Update from the SnakeFeet Team 🐍👣</h2>
                  <div className="blog-meta">
                    <ul>
                      <li>
                        bY Byron Ma February 9, 2024
                      </li> <br/> <br/> <br/> <br/>
                    </ul>
                  </div>
                  <p style= {{color: 'black'}}>Hey there, SnakeFeet community! 👋</p>
                  <p style= {{color: 'black'}}>
                    We hope you're as excited as we are about the progress on{' '}
                    <em>Sixth Sense</em>. It's been an incredible journey so
                    far, and we're thrilled to share some major updates with you
                    all. Our teams have been working tirelessly, and here's
                    what's been happening recently behind the scenes:
                  </p>
                  <p style= {{color: 'black'}}>Art Team Updates 🎨</p>
                  <p style= {{color: 'black'}}>
                    Our Art Team has some fun updates to share. We're proud to
                    announce that all the core characters planned for release
                    are now complete. But that's not all - the next phase is
                    bringing these characters to life with animations. We're
                    focusing not only on core actions but also on creating fun
                    and dynamic emotes that will bring a unique charm to each
                    character. 😄
                  </p>
                  <p style= {{color: 'black'}}>
                    Additionally, our game map is shaping up nicely and is
                    already in a testable state. We're excited about making it
                    bigger and richer, ensuring it provides the perfect backdrop
                    for our game's thrilling action. New UI will also enhance
                    feature clarity and overall user experience. 🗺️
                  </p>
                  <p style= {{color: 'black'}}>Programming Team's Forward Strides 💻</p>
                  <p style= {{color: 'black'}}>
                    The Programming Team has been on a roll, with servers now up
                    and running, allowing us to playtest <em>Sixth Sense</em>{' '}
                    with people from all around the world. We are also steadily
                    introducing new features. Core gameplay mechanics are in
                    place, and we're focusing on refining aspects like spectate
                    mode and an updated Sixth Sense format, among others. 🌍
                  </p>
                  <p style= {{color: 'black'}}>
                    Looking ahead, we're excited to develop additional game
                    structures and features, such as the customization page and
                    post-game screen. The positive progress we're making is a
                    testament to our team's hard work and dedication, and we're
                    confident that our audience will love what we have in store.
                    🚀
                  </p>
                  <p style= {{color: 'black'}}>Music Team's Achievements 🎶</p>
                  <p style= {{color: 'black'}}>
                    Our Music Team has been working magic with their
                    compositions. The main menu theme is complete and is set to
                    become an iconic tune that enhances the game's overall feel
                    and ambience. Now, the team is turning their talents towards
                    creating an immersive in-game theme. We can't wait for you
                    to hear it! 🎧
                  </p>
                  <p style= {{color: 'black'}}>Looking Forward 🔮</p>
                  <p style= {{color: 'black'}}>
                    Stay tuned for more updates as we look forward to sharing
                    more milestones with you. 🎉
                  </p>
                  <p style= {{color: 'black'}}>Warm regards,</p>
                  <p style= {{color: 'black'}}>The SnakeFeet Studios Team 🐍👣</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Blog1;