import React, { useState, useEffect } from 'react';



function LatestMatch() {
  const imageData = [
    {
      id: 1,
      src: '/assets/img/images/sm/logodisc1.png',
      link: 'https://discord.gg/4UhqjXHnq6',
    },
    {
      id: 2,
      src: '/assets/img/images/sm/logoreddit1.png',
      link: 'https://www.reddit.com/r/sixthsensegame/',
    },
    {
      id: 3,
      src: '/assets/img/images/sm/logox1.png',
      link: 'https://twitter.com/playsixthsense',
    },
    {
      id: 4,
      src: '/assets/img/images/sm/logoins1.png',
      link: 'https://www.instagram.com/sixthsensegame/',
    },
    {
      id: 5,
      src: '/assets/img/images/sm/logotiktok1.png',
      link: 'https://www.tiktok.com/@sixthsensegame',
    },
    {
      id: 6,
      src: '/assets/img/images/sm/logoyoutube1.png',
      link: 'https://www.youtube.com/@SixthSenseGame',
    },
    {
      id: 7,
      src: '/assets/img/images/sm/logotwitch1.png',
      link: 'https://www.twitch.tv/sixthsensegame',
    },
  ];

  const [responsiveStyles, setResponsiveStyles] = useState({
    fontSize: '30px',
    padding: '0 20px',
    maxWidth: '60%',
  });

  useEffect(() => {
    const updateStylesBasedOnWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setResponsiveStyles({
          fontSize: '17px',
          padding: '0 10px',
          maxWidth: '90%',
        });
      } else {
        setResponsiveStyles({
          fontSize: '30px',
          padding: '0 20px',
          maxWidth: '60%',
        });
      }
    };

    updateStylesBasedOnWidth();
    window.addEventListener('resize', updateStylesBasedOnWidth);

    return () => window.removeEventListener('resize', updateStylesBasedOnWidth);
  }, []);

  return (
    <div>

    <section style={{ backgroundColor: '#b5b5b5', color: 'white', padding: '50px 50px', textAlign: 'center' }}>

      <div className="container" style={{ maxWidth: '100%', alignContent: 'center' }}>
        <div className="row justify-content-center mb-5">
          <div className="col-12 text-center">
          <img src='/assets/img/logo/happybear.png' alt='SFS Logo' style={{width: '50%', margin: '-60px 0 0 50px'}} />
            <h2 style={{ color: 'black', fontSize: '2.5rem' }}>Follow us!</h2>
          </div>
        </div>
        <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
  {imageData.map((image) => (
    <div key={image.id}>
      <a href={image.link} target="_blank" rel="noopener noreferrer">
        <img src={image.src} alt={`pic`} className="img-fluid" style={{width: '50px', height: 'auto', margin: '6px' }} />
      </a>
    </div>
  ))}
</div>
      </div>
    </section>
    <section style={{ backgroundColor: '#393939', color: 'white', marginTop: '50px', textAlign: 'center' }}>

<div className="container" style={{ maxWidth: '100%' }}>
  <div className="row justify-content-center mb-5">
    <div className="col-12 text-center">
      <h2 style={{ color: 'white', fontSize: '2.5rem' }}>About Us</h2>
      <p style={{ fontSize: responsiveStyles.fontSize, fontWeight: 'normal', marginBottom: '40px', lineHeight: '1.5' }}>
     We’re a small indie studio, created and brought together by Byron and Ethan Ma. The studio was established in mid to late 2023 and started developing Sixth Sense, our first video game aimed to be released in 2024. We’re a small team and we work on all aspects of the project, varying from art to game design to programming to music. We hope you follow our socials and keep up with our journey and support us before, during, and after the game comes out! As we gear up for the release, meet the faces behind Snake Feet Studios!
          </p>    </div>
          {/* <div className="col-12 text-center">
      <h2 style={{ color: 'white', fontSize: '2.5rem' }}>Contributors</h2>
      <p style={{ fontSize: responsiveStyles.fontSize, fontWeight: 'normal', marginBottom: '40px', lineHeight: '1.5' }}>
      Bobsi, who helped us greatly with programming, including implementing a multiplayer system and servers; Will Lin, who helped create and was the basis of so many of our art assets; Sam Chang, who helped create the architecture of our map/floor plan as well as helping out with animation; Johnny and Martinius, who created and developed sound effects and soundtracks; and many others.
          </p>    </div> */}
          
  </div>
</div>
</section>
    </div>
    
  );
}

export default LatestMatch;