import React from 'react';

function Video() {
  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '-100px', marginBottom: '-100px' }}>
            <h1 style={{ color: 'white', textAlign: 'center' }}>Sixth Sense trailer released!</h1>
      <div style={{ maxWidth: '800px', width: '100%', marginBottom: '20px', marginTop: '-100px' }}>
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
<iframe style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', marginBottom: '-50px' }} width="1038" height="584" src="https://www.youtube.com/embed/GTEaZVqoTrU" title="Sixth Sense Gameplay Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <h1 style={{ color: 'white', textAlign: 'center', fontSize: '200px', marginTop: '120px' }}>SIXTH SENSE</h1>
        </div>
      </div>
    </div>
  );
}

export default Video;