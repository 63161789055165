  import React from 'react';
  function ContactArea() {
  const handleEmailClick = () => {
  window.location.href = 'mailto:development@snakefeet.io';
  };
  return (
  <section className="contact-area pt-80 pb-80">
  <div className="container">
  <div className="row">
  <div className="col-lg-12 text-center">
  <h1 className="mb-4" style={{ fontSize: '3rem', marginTop: '5rem' }}>
  Reach Us via Email
  </h1>
  <a
  href="mailto:development@snakefeet.io"
  style={{
  padding: '0.5rem 1rem',
  backgroundColor: 'black',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  }}
  >
  Contact Us
  </a>
  </div>
  </div>
  <div className="d-flex justify-content-center mb-4 mt-5">
  <div className="mr-4">
  <h2>Have Questions?</h2>
  <p>
  <u>Click here for our FAQs.</u>
  </p>
  </div>
  <div>
  <h2>Are you a journalist,</h2>
  <h2>or looking for art assets?</h2>
  <p>
  <u>Click here for the press kit.</u>
  </p>
  </div>
  </div>
  </div>
  </section>
  );
  }


export default ContactArea;