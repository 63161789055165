import React from 'react';
import Slider from 'react-slick';

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      >
    </button>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
      <div></div>
        );
}

function GamesArea() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    nav: false,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <NextArrow />,
    nextArrow: <PrevArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          Padding: '100px 50px',
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="area-bg-one">
      {/* latest-games-area */}
      <section className="latest-games-area pt-120">
        <div className="container" style={{ marginTop: '-80px' }}>
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="section-title mb-50">
                <h2>Latest News</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider className="latest-games-active slick" {...settings}>
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href="/04-19">
                    <img src="/assets/img/images/newsthumbnail.jpeg" alt="" />
                    </a>
                  </div>
                  <div className="latest-games-content">
                    <div className="lg-tag">
                      <a href="/04-19">blog</a>
                    </div>
                    <h4>
                      <a href="/04-19">Weekly Progress Report - April 19, 2024</a>
                    </h4>
                  </div>
                </div>
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href="/04-12">
                      <img src="/assets/img/images/newsthumbnail.jpeg" alt="" />
                    </a>
                  </div>
                  <div className="latest-games-content">
                    <div className="lg-tag">
                      <a href="/04-12">blog</a>
                    </div>
                    <h4>
                      <a href="/04-12">Weekly Progress Report - April 12, 2024</a>
                    </h4>
                  </div>
                </div>
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href="/04-05">
                    <img src="/assets/img/images/newsthumbnail.jpeg" alt="" />
                    </a>
                  </div>
                  <div className="latest-games-content">
                    <div className="lg-tag">
                      <a href="/04-05">blog</a>
                    </div>
                    <h4>
                      <a href="/04-05">Weekly Progress Report - April 5, 2024</a>
                    </h4>
                  </div>
                </div>
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href="/03-29">
                    <img src="/assets/img/images/newsthumbnail.jpeg" alt="" />
                    </a>
                  </div>
                  <div className="latest-games-content">
                    <div className="lg-tag">
                      <a href="/03-29">blog</a>
                    </div>
                    <h4>
                      <a href="/03-29">Weekly Update: New Game Features and Progress</a>
                    </h4>
                  </div>
                </div>
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href="/03-22">
                    <img src="/assets/img/images/newsthumbnail.jpeg" alt="" />
                    </a>
                  </div>
                  <div className="latest-games-content">
                    <div className="lg-tag">
                      <a href="/03-22">blog</a>
                    </div>
                    <h4>
                      <a href="/03-22">GDC Recap - March 22, 2024</a>
                    </h4>
                  </div>
                </div>
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href="/03-09">
                    <img src="/assets/img/images/newsthumbnail.jpeg" alt="" />
                    </a>
                  </div>
                  <div className="latest-games-content">
                    <div className="lg-tag">
                      <a href="/03-09">blog</a>
                    </div>
                    <h4>
                      <a href="/03-09">GDC Trip and Development Progress - March 9, 2024</a>
                    </h4>
                  </div>
                </div>
                <div className="latest-games-items mb-30">
                  <div className="latest-games-thumb">
                    <a href="/02-09">
                    <img src="/assets/img/images/newsthumbnail.jpeg" alt="" />
                    </a>
                  </div>
                  <div className="latest-games-content">
                    <div className="lg-tag">
                      <a href="/02-09">blog</a>
                    </div>
                    <h4>
                      <a href="/02-09">Exciting Progress Update from the SnakeFeet Team</a>
                    </h4>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* latest-games-area-end */}
      {/* about-area */}

      {/* about-area-end */}
    </div>
  );
}

export default GamesArea;