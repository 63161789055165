import React from 'react';

const imageStyle = {
  width: '70%',
  height: 'auto',
  borderRadius: '10px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
};

function ShopArea() {
  return (
    <section className="shop-area pt-120 pb-90">
      <div className="container" style={{ alignContent: 'center'}}>
      <h1 style={{ fontSize: '70px', fontWeight: 'bold', marginBottom: '40px', alignContent: 'center' }}>Press Kit</h1>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="accessories-item text-center mb-80">
              <div className="accessories-thumb mb-30">
                <img src="/assets/img/images/species2/Bear_V2-1.png" alt="" style={imageStyle} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="accessories-item text-center mb-80">
              <div className="accessories-thumb mb-30">
              <img src="/assets/img/images/species2/Bear_V2-2.png" alt="" style={imageStyle} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="accessories-item text-center mb-80">
              <div className="accessories-thumb mb-30">
              <img src="/assets/img/images/species2/Bear_V2-3.png" alt="" style={imageStyle} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="accessories-item text-center mb-80">
              <div className="accessories-thumb mb-30">
              <img src="/assets/img/images/species2/Bear_V2-4.png" alt="" style={imageStyle} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="accessories-item text-center mb-80">
              <div className="accessories-thumb mb-30">
              <img src="/assets/img/images/species2/Bear_V2-5.png" alt="" style={imageStyle} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="accessories-item text-center mb-80">
              <div className="accessories-thumb mb-30">
              <img src="/assets/img/images/species2/Bear_V2-6.png" alt="" style={imageStyle} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShopArea;