import React from 'react';

function SingleArea() {
  return (
    <section className="game-single-area pt-120 pb-120">
      <div className="container">
        <div className="row">
          {/* Left Side */}
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src="assets/img/square-image.jpg" alt="" style={{ maxWidth: '100%', maxHeight: '400px', objectFit: 'cover' }} />
          </div>

          {/* Right Side */}
          <div className="col-md-6">
            <div className="row">
              <div className="col-12 mb-4 d-flex justify-content-center align-items-center">
                <img src="assets/img/rectangular-image1.jpg" alt="" style={{ maxWidth: '100%', maxHeight: '195px', objectFit: 'cover' }} />
              </div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                <img src="assets/img/rectangular-image2.jpg" alt="" style={{ maxWidth: '100%', maxHeight: '195px', objectFit: 'cover' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SingleArea;
