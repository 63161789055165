import React from 'react';
import Slider from 'react-slick';




function Brand() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2000, // Controls the interval between each slide
    autoplay: true,
    arrows: false,
    slidesToShow: 8,
    slidesToScroll:1,
    center:false,
    swipe: false, // Disable swipe
    draggable: false, // Disable dragging
    pauseOnHover: false,
  }
  return(
	        <div className="brand-area brand-bg" style={{marginTop: '30px'}}>
        <div className="container">
          <Slider className="row brand-active" {...settings}>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-1.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-2.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-3.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-4.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-5.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-6.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-7.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-8.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-9.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-10.png" alt="" />
              </div>
            </div>
            <div className="col-xl">
              <div className="brand-item">
              <img src="assets/img/images/species2/Bear_V2-11.png" alt="" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
  )
}

export default Brand;
