import React from 'react';
import Breadcrumb from './Breadcrumb';
import HeaderOne from '../../components/header/HeaderOne';
import Footer from '../../components/footer/Footer';
import BlogContent from './BlogContent';

function Blog6() {
  return (
    <>
      <HeaderOne />
      <main>
        <section className="blog-area primary-bg pt-120 pb-175">
          <div className="container">
            <div className="row">
              <div className="blog-list-post blog-details-wrap">
                <div className="blog-list-post-content">
                  <div className="blog-list-post-tag mb-25">
                    <a href="/#">Weekly Progress Report 📊</a>
                  </div>
                  <h2>🎮 Weekly Game Design Update! 🌿🏰</h2>
                  <div className="blog-meta">
                    <ul>
                      <li>
                        bY <a href="/#">Byron Ma</a> April 12, 2024
                      </li> <br/>
                    </ul>
                  </div>
                  <p style={{ color: 'black' }}>
                    New Theme: Lost City in the Forest! We've made a significant
                    shift from our initial mansion theme to a mesmerizing 'Lost
                    City in the Forest' concept. Here's a glimpse into our
                    creative process:
                  </p>
                  <ul style={{ color: 'black' }}>
                    <li>
                      Brainstormed diverse concepts blending fantasy with a
                      modern twist, aiming for a captivating narrative. 💡
                    </li> <br/>
                    <li>
                      Strived for a balance of suspense and charm in our design,
                      ensuring an immersive experience for players. ✨
                    </li> <br/>
                    <li>
                      Gathered inspirational imagery and sketched out initial
                      map layouts to bring our vision to life. 🎨
                    </li> <br/>
                    <li>
                      Designed the map to be more square/circular, enhancing
                      gameplay dynamics and exploration. 🗺️
                    </li> <br/>
                  </ul>
                  <p style={{ color: 'black' }}>
                    Revamped Characters: Our beloved bears have transformed into
                    enchanting figures, embodying roles like wizards, thieves,
                    and alchemists. This revamp adds depth and uniqueness to
                    each character:
                  </p>
                  <ul style={{ color: 'black' }}>
                    <li>
                      Bears now boast unique, powerful traits that align with
                      their new roles, offering players diverse playstyles. 🐻🧙
                    </li> <br/>
                  </ul>
                  <p style={{ color: 'black' }}>New Features Unveiled!</p>
                  <ul style={{ color: 'black' }}>
                    <li>
                      'Info Stations' provide in-game guidance and delve into
                      the rich lore of our world, enhancing immersion and
                      storytelling. ℹ️
                    </li> <br/>
                    <li>
                      Introducing 'Hero Powers' to our arsenal, offering players
                      new dimensions of strategy and gameplay. 🦸
                    </li> <br/>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Blog6;