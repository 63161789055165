import React, { useState, useEffect } from 'react';

function GameSingleArea() {
  const textStyle = {
    marginLeft: 'auto',
    fontSize: 'calc(2vw + 1vh + 1vmin)',
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: 'normal',
  };

  const textStyle4 = {
    color: '#90DEB2',
    marginLeft: 'auto',
    fontSize: 'calc(1vw + 1vh + 2vmin)', // Responsive font size using viewport units
    fontWeight: 'bold',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: 'normal',
  };

  const textStyle3 = {
    marginLeft: 'auto',
    opacity: '0.3',
    fontSize: 'calc(1vw + 1vh + 2vmin)', // Responsive font size using viewport units
    fontWeight: 'bold',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: 'normal',
  };

  const imageStyle = {
    marginBottom: '10px',
    maxHeight: '70px',
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const rectangleStyle = {
    backgroundColor: '#2a2a2a',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '20px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: isMobile ? 'row' : 'row',
    textAlign: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
    maxWidth: '100%',
    margin: '20px auto',
    minWidth: '300px',
  };

  const teamInfoContainerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#2c2c2c',
    color: 'white',
    padding: isMobile ? '20px' : '60px 100px',
  };

  const bearImageStyle = {
    height: '200px',
    margin: isMobile ? '20px 0' : '0 20px',
  };

  const [responsiveStyles, setResponsiveStyles] = useState({
    fontSize: '30px',
    padding: '0 20px',
    maxWidth: '60%',
  });

  useEffect(() => {
    const updateStylesBasedOnWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setResponsiveStyles({
          fontSize: '17px',
          padding: '0 10px',
          maxWidth: '90%',
        });
      } else {
        setResponsiveStyles({
          fontSize: '30px',
          padding: '0 20px',
          maxWidth: '60%',
        });
      }
    };

    updateStylesBasedOnWidth();
    window.addEventListener('resize', updateStylesBasedOnWidth);

    return () => window.removeEventListener('resize', updateStylesBasedOnWidth);
  }, []);

  return (
    <>
      <section style={{ backgroundColor: '#2c2c2c', color: 'white', padding: '50px 50px', textAlign: 'center' }}>
          <p style={{ fontSize: responsiveStyles.fontSize, fontWeight: 'normal', marginBottom: '40px', lineHeight: '1.5' }}>
        SnakeFeet Studios is pioneering a new genre in video games by adapting the popular campus game Assassins into Sixth Sense, the first deduction-based battle royale, enabling players globally to engage in a thrilling stealth and strategy experience from anywhere.        </p>
      </section>

      <section className="game-single-area pt-120 pb-180" style={{ marginBottom: '-150px' }}>
        <h1 style={{ color: 'white', padding: '20px', fontSize: '70px', fontWeight: 'bold', marginBottom: '30px', textAlign: 'center', marginTop: '-50px' }}>Coming Soon</h1>

        <div className="container">
          <div className="row align-items-center" style={{ marginBottom: '60px' }}>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
              <img src="assets/img/images/app-icon.png" alt="" style={{ maxWidth: '100%', maxHeight: '400px', objectFit: 'cover', height: 'auto' }} />
            </div>

            <div className="col-lg-6 col-md-6" style={{ marginTop: '20px' }}>
            <img src="assets/img/images/BuyGame.png" alt="" style={{ maxWidth: '100%', maxHeight: '400px', objectFit: 'cover', height: 'auto' }} />
            </div>
          </div>
        </div>
      </section>

      <section style={teamInfoContainerStyle}>
        <img src="assets/img/images/species2/Bear_V2-11.png" alt="" style={bearImageStyle} />
        <div style={{ maxWidth: responsiveStyles.maxWidth, padding: responsiveStyles.padding }}>
          {/* <h1 style={{ fontSize: '70px', fontWeight: 'bold', marginBottom: '40px' }}>Where We Started</h1> */}
          <p style={{ fontSize: responsiveStyles.fontSize, fontWeight: 'normal', marginBottom: '40px', lineHeight: '1.5' }}>
          Sixth Sense transforms the classic campus game of Assassins into a stealth and deduction-based battle royale set within a hidden city. In this game, players choose from classes such as thieves, wizards, and alchemists, each equipped with unique abilities and tactical advantages. Players must use a mix of stealth, puzzle-solving, and social deduction to track or evade others in a high-stakes hunt. Players navigate through shadowy landscapes while reading maps, forming alliances, and hiring spies to be the last one standing in this test of cunning and strategy.          </p>
          <div className="about-btn" style={{ marginTop: '40px' }}>
            <a href="/about" className="btn" style={{ fontSize: '30px' }}>Meet the team!</a>
          </div>
        </div>
        <img src="assets/img/images/species2/Bear_V2-10.png" alt="" style={bearImageStyle} />
      </section>
    </>
  );
}

export default GameSingleArea;